<!--
 * @Author: sopen
 * @Date: 2021-06-30 10:04:37
 * @LastEditTime: 2021-12-03 14:58:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-uscenter/src/views/home/usinfo.vue
-->
<template>
  <v-app>
    <v-container grid-list-xs class="px-3">
      <v-card flat @click="dialog = true">
        <v-card-title primary-title class="pb-0 px-0">
          {{ usinfo.NAME }}
          {{ usinfo.TYPE == "teacher" ? "老师" : "同学" }}
        </v-card-title>
        <v-card-text class="pt-0 px-0">
          {{ usinfo.SCHOOLNAME }}
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="dialog"
        scrollable
        :fullscreen="mobile"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title class="pr-2">
            切换身份
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-4">
            <v-row>
              <v-col cols="12" v-if="Object.keys(teacher).length > 0">
                <v-card
                  tile
                  outlined
                  v-for="(item, x) in teacher"
                  :key="x"
                  @click="reusrec('T' + x)"
                >
                  <v-card-title primary-title class="pb-0">
                    {{ item.name }}老师
                  </v-card-title>
                  <v-card-text class="pt-0">
                    {{ school[item.school].name }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="Object.keys(student).length > 0">
                <v-card
                  tile
                  outlined
                  v-for="(item, y) in student"
                  @click="reusrec('S' + item.id)"
                  :key="y"
                >
                  <v-card-title class="text-h8" primary-title>
                    {{ item.name }}同学
                  </v-card-title>
                  <v-card-text class="pt-0">
                    {{ school[item.school].name }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="parents.length > 0">
                <v-card
                  tile
                  outlined
                  v-for="(item, x) in parents"
                  :key="x"
                  @click="reusrec('S' + item.id)"
                >
                  <v-card-title class="text-h8" primary-title>
                    {{ item.name }}的家长
                  </v-card-title>
                  <v-card-text class="pt-0">
                    {{ school[item.school].name }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
export default {
  components: {},
  props: {
    mobile: Boolean,
  },
  data() {
    return {
      menu: [],
      usinfo: {},
      teacher: {},
      student: {},
      parents: [],
      school: null,
      dialog: false,
    };
  },
  mounted() {
    //console.log("index");
    this.reusrec();
  },
  methods: {
    reusrec(targetid = "") {
      let tokenValue = this.$sopen.getUsInfo();
      let data = {
        module: "usinfo",
        title: "reusrec",
        data: {
          cookie: JSON.stringify(tokenValue),
          targetid: targetid,
          url: "http://luohu.sopen.cn",
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        ////console.log(res);

        that.usinfo = res.data.usinfo.usinfo;

        if (that.usinfo.INFO.teacher && that.usinfo.INFO.teacher.data) {
          that.teacher = that.usinfo.INFO.teacher.data;
        }
        if (that.usinfo.INFO.student && that.usinfo.INFO.student.data) {
          that.student = that.usinfo.INFO.student.data;
        }
        if (that.usinfo.INFO.parents && that.usinfo.INFO.parents.data) {
          for (let x in that.usinfo.INFO.parents.data) {
            that.parents.push(that.usinfo.INFO.parents.data[x]);
          }
        }
        if (that.usinfo.INFO.school && that.usinfo.INFO.school.data) {
          that.school = that.usinfo.INFO.school.data;
        }
        if (targetid != "") {
          //console.log("/token?openuid=" + res.data.usinfo.openuid);
          /*
          that.$router.push({
            path: "/token?openuid=" + res.data.usinfo.openuid,
          });
          //*/
          that.dialog = false;
          that.target(res.data.usinfo.openuid);
        }
        //console.log(that.student);
      });
    },
    target(openuid) {
      let data = {
        module: "user_info",
        title: "setcookie",
        res: openuid,
      };
      //console.log(data);
      this.$sopen.requestApi(data).then(function (res) {
        //console.log(res);
        localStorage.setItem("uskey", JSON.stringify(res.errmsg));
        window.location.reload();
        /*
        that.$router.push({
          path: "/home/",
        });
        //*/
      });
    },
  },
};
</script>